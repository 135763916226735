<template>
  <!-- 外请车对账单-列表 -->
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main mb24px">
        <ayl-new-table
          v-loading="loading"
          :data="tableData"
          pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :pager-count="5"
          :current-page.sync="current"
          :total="totalNum"
          :page-size="10"
          @size-change="handleSizeChange"
          @ayl-current-change="handleCurrentChange"
          @getSearchPramas="getSearchPramas"
        >
          <template #after>
            <div>
              <el-button style="margin: 0 20px" type="primary" @click="onExport">导出</el-button>
            </div>
            <div class="go-back"><el-button @click="$router.go(-1)">返回</el-button></div>
          </template>
        </ayl-new-table>

        <!-- 对账 -->
        <el-dialog title="对账" :visible.sync="dialogVisible" width="450px" center>
          <el-form
            :model="form"
            :rules="rules"
            ref="form"
            :inline="true"
            label-position="right"
            label-width="120px"
            style="display: flex; flex-wrap: wrap"
          >
            <div class="title-font">对账信息</div>
            <el-form-item label="对账单名称：">
              <el-input
                v-model="form.accountCheckingName"
                maxlength="64"
              ></el-input>
            </el-form-item>
            <el-form-item label="结算客户：">
              <el-input
                v-model="form.partnerAccountName"
                disabled
                maxlength="64"
              ></el-input>
            </el-form-item>

            <el-form-item label="应付金额：">
              <el-input v-model="form.payAbleAmount" disabled maxlength="64"></el-input>
            </el-form-item>
            <div class="title-font" style="margin-top: 10px">对账调整</div>
            <el-form-item label="确认金额：" prop="accountCheckingAmount">
              <el-input v-model="form.accountCheckingAmount" type="number"></el-input>
            </el-form-item>
            <el-form-item label="备注：">
              <el-input v-model="form.accountCheckingRemark" maxlength="64"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="clickSubmit"
              class="mr24px"
              :loading="btnLoading"
              >确定</el-button
            >
            <el-button @click="resetForm">返回</el-button>
          </span>
        </el-dialog>

        <!--导出-->
        <el-dialog
          title="选择导出的数据"
          :visible.sync="dialogExport"
          width="900px"
          center
        >
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
            <el-checkbox
              :label="item.index"
              v-for="(item, idx) in checkList"
              :key="idx"
              >{{ item.value }}</el-checkbox
            >
          </el-checkbox-group>
          <span slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="exportExcel"
              class="mr24px"
              :loading="btnLoading"
              >确 定</el-button
            >
            <el-button @click="dialogExport = false">取 消</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { Select } from "element-ui";
import mixins from '@utils/mixins'
import newTableMixins from "../../utils/new-table-mixins";
export default {
  name: "",
  mixins: [mixins.$exportCheckAll,newTableMixins],
  components: {},
  data() {
    return {
      nav: [
        { name: "外请车对账" },
        { name: "对账单列表", path: "/accounting/outer-driver-list" },
      ],

      btnLoading: false,
      dialogVisible: false,
      checkIndex: [],
      checkList: [],
      dialogExport: false,
      form: {
        accountCheckingId: null,
        accountCheckingName: null,
        partnerAccountName: null,
        payAbleAmount: null,
      },
      rules: {
        accountCheckingName: [
          {
            required: true,
            message: "请输入对账名称",
            trigger: "blur",
          },
          { validator: this.$validator.isContainBlank },
        ],
        accountCheckingAmount: [
          { required: true, message: "请输入调整金额", trigger: "blur" },
          { validator: this.$validator.isTwoDecimal },
        ],
      },

      tableData: {
        api: this.$api.accounting_getWQCheckingList,
        query: {
          startTime: null,
          endTime: null,
          accountStatus: null,
          queryContent: null,
        },
        searchData: [
          {
            title: "对账单生成日期",
            type: "dateSection_zl",
            model: ["startTime", "endTime"],
          },
          {
            title: "对账单状态",
            type: "select",
            model: "accountStatus",
            placeholder: "请选择",
            option: this.$enums.accountType.list,
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "对账单名称",
          },
        ],

        columns: [
          {
            title: "对账单编号",
            key: "accountCheckingId",
            showTooltip: true,
          },
          {
            title: "对账单名称",
            key: "accountCheckingName",
            showTooltip: true,
          },
          {
            title: "对账单生成日期",
            showTooltip: true,
            key: "generateDate",
          },
          {
            title: "对账单确认日期",
            key: "confirmDate",
            showTooltip: true,
          },
          {
            title: "明细数量",
            key: "orderCount",
            showTooltip: true,
          },
          {
            title: "应付金额",
            key: "payableAmount",
            showTooltip: true,
          },
          {
            title: "对账调整金额",
            key: "accountCheckingAmount",
            showTooltip: true,
          },
          {
            title: "账单状态",
            key: "accountStatus",
            showTooltip: true,
            render: (h, ctx) => {
              let text = ctx.row.accountStatus == "1" ? "对账已完成" : "对账中";
              return h("span", text);
            },
          },
          {
            title: "账单备注",
            key: "accountCheckingRemark",
            showTooltip: true,
          },
          {
            title: "操作",
            width: "160px",
            align: "center",
            render: (h, ctx) => {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view": true,
                    },
                    on: {
                      click: () => {
                        this.onDetail(ctx.row);
                      },
                    },
                  },
                  "订单明细"
                ),
                h(
                  "el-button",
                  {
                    class: {
                      "table-view": true,
                    },
                    props: {
                      type: "text",
                      disabled: ctx.row.accountStatus == "1" ? true : false,
                    },
                    on: {
                      click: () => {
                        this.onAccounting(ctx.row);
                      },
                    },
                  },
                  "对账"
                ),
                h(
                  "el-button",
                  {
                    class: {
                      "table-view": true,
                    },
                    props: {
                      type: "text",
                      disabled: ctx.row.orderCount > 0 ? true : false,
                    },
                    on: {
                      click: () => {
                        this.onDelete(ctx.row);
                      },
                    },
                  },
                  "删除"
                ),
              ]);
            },
          },
        ],
        data: [],
      },
    };
  },
  methods: {
    onExport(onExport) {
      this.dialogExport = true;
      this.$api.GetExcelExportKeyAndColumn({
        excelKey: "getWQCheckingList",
      })
      .then((res) => {
        this.checkList = res.chsList;
        this.checkIndex = this.checkList.map((e) => {
          return e.index;
        });
      });

    },
    // 导出
    async exportExcel() {
      this.btnLoading = true;
      try {
        const url = await this.$api.Excel({
          excelKey: "getWQCheckingList",
          indexList: this.checkIndex,
          params: {
            startTime: this.tableData.query.startTime || null,
            endTime: this.tableData.query.endTime || null,
            accountStatus: this.tableData.query.accountStatus,
            queryContent: this.tableData.query.queryContent,
          },
        });
        window.open(url);
        this.dialogExport = false;
      } catch (e) {
        // console.log(e)
      }
      this.btnLoading = false;
    },
    // 删除空的对账单
    onDelete(row) {
      console.log(row.accountCheckingId);
      if (!row.accountCheckingId) {
        return this.$message({
          message: "请选择需要删除的对账单",
          type: "warning",
        });
      }
      this.$api
        .accounting_deleteAccountChecking({ id: row.accountCheckingId })
        .then((res) => {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          });
          this.getListData();
        });
    },
    onDetail(row) {
      this.$router.push({
        path: "/accounting/outer-driver-details",
        query: {
          accountCheckingId: row.accountCheckingId,
          queryId: this.$route.query.partnerAccountId,
          accountStatus: row.accountStatus,
        },
      });
    },
    // 获取对账信息
    onAccounting(row) {
      if (!row.orderCount) {
        return this.$message({
          message: "警告，对账单订单数量为0，无法进行对账",
          type: "warning",
        });
      }
      this.dialogVisible = true;
      this.$api
        .accounting_WQAccountCheckingBackInfo({
          accountCheckingId: row.accountCheckingId,
        })
        .then((res) => {
          this.form = res;
        });
    },
    // 对账
    clickSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let params = {
            accountCheckingAmount: this.form.accountCheckingAmount,
            accountCheckingName: this.form.accountCheckingName,
            accountCheckingId: this.form.accountCheckingId,
            accountCheckingRemark: this.form.accountCheckingRemark,
            payableAmount: this.form.payAbleAmount,
          };
          await this.$api
            .accounting_WQAccountChecking({ steelAccountCheckingInfoVO: params })
            .then((res) => {
              this.getListData();
            });
          this.resetForm();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="css" scoped>
.title-font {
  width: 100%;
  margin-bottom: 20px;
  font-weight: 700;
}
.el-form-item--mini.el-form-item {
  display: flex;
  width: 100%;
}
::v-deep .el-form-item--mini .el-form-item__content {
  flex: 1;
}
/* ::v-deep .search-wrap {
  justify-content: space-between;
} */
::v-deep .search-item {
  margin-right: 20px;
}
::v-deep .search-wrap > .search-item:last-child {
  margin-right: 0;
}
.go-back {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
::v-deep .el-dialog--center .el-dialog__body {
  padding-bottom: 10px;
}
::v-deep .el-input.is-disabled .el-input__inner {
  color: #303133;
}
</style>
